import React from 'react'
import './Store.scss'
import homeIcon from '../../../assets/images/store/home.svg'
import BGPage from '../../../assets/images/store/bg.webp'
import Button from '../../common/Button/Button'
import {links} from "../../../constants/links";

const Store = () => {
  return (
    <section style={{backgroundImage: `url(${BGPage})`}} id='store' className='Store'>
      <div className="bg"/>
      <div className="container-info">
        <span>CONHEÇA A MIDAS STORE</span>
        <div className="info-store">
          <p className='describe'>Pensando em facilitar o seu acesso a produtos da melhor qualidade, a Midas Academy criou uma loja especialmente para você!</p>
             <p className='spot'>EM BREVE <span>EM BREVE</span> EM BREVE <span> EM BREVE</span> EM BREVE <span> EM BREVE</span></p>
          <Button img={homeIcon} href={links.contact.midasStore}>RECEBER ATUALIZAÇÕES</Button>
        </div>
      </div>
    </section>
  )
}

export default Store
