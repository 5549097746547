import React from 'react';
import './WhatsAppButton.scss';
import image from '../../../assets/images/WhatsApp_icon.webp'

function WhatsAppButton() {
  return (
    <a href="https://api.whatsapp.com/send?phone=5511966035555&text=Ol%C3%A1,%20Gostaria%20de%20agendar%20uma%20visita" className="whatsapp-float" target="_blank" rel="noreferrer">
      <img src={image} alt="WhatsApp" title="WhatsApp" />
    </a>
  );
}

export default WhatsAppButton;
