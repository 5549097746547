export const relatedCourses = [
  {
    name: "DO IT YOURSELF ",
    describe: "",
    category: "Especial",
    image: require("../assets/images/courses/new-paste/score.webp"),
    information: `<p>Seja sua própria gravadora é um curso destinado a quem deseja dominar todos os aspectos da indústria fonográfica, desde o conceito artístico até o lançamento de álbuns e a organização de shows. Nossas aulas são ministradas por profissionais experientes do mercado fonográfico brasileiro, oferecendo orientação prática e estratégica no campo do music business, para que você possa trilhar seu caminho com confiança e sucesso.
    </p>
    <p>
      ✔ Conceito artístico<br/>
      ✔ Repertório<br/>
      ✔ Arranjos<br/>
      ✔ Direitos autorais <br/>
      ✔ Fotografia<br/>
      ✔ Vídeo<br/>
      ✔ Redes sociais<br/>
      ✔ Plano de mktg<br/>
      ✔ Estratégia e Lançamentos <br/>
      ✔ Radio tv e imprensa<br/>
    </p>`
  },
  {
    name: "COMPOSIÇÃO",
    describe: "",
    category: "Especial",
    image: require("../assets/images/courses/new-paste/producer.webp"),
    information: `<p>Aprenda o segredo dos verdadeiros Hits. Desenvolva todos os elementos da composição, misturando técnicas  focadas no mercado da música atual.</p>
    <p>
    ✔ Aprender a desenvolver o conceito e ideia principal    <br/>
    ✔ Harmonizar e re harmonizar utilizando o campo harmônico e empréstimo modal    <br/>
    ✔ Funções da narrativa    <br/>
    ✔ Compondo para outros artistas    <br/>
    ✔ Composição e mercado    <br/>
    ✔ Desenvolver e aprimorar esquemas de rima    <br/>
    ✔ Harmonia funcional: Funções dos acordes    <br/>
    ✔ Formas de melodia: Do clássico ao contemporâneo    <br/>
    ✔ Gravando sua Demo    <br/>
    ✔ Direitos autorais e contratos
    </p>`
  },
  {
    name: "THE PRODUCER",
    describe: "",
    category: "Especial",
    image: require("../assets/images/courses/new-paste/demo-tape_50.webp"),
    information: `<p>O curso de produção musical é ideal para quem quer aprimorar suas habilidades em gravação, mixagem e masterização, com orientação em direção artística, no maior complexo de estúdios da América Latina. O programa cobre desde o conceito até a masterização, proporcionando aos alunos orientação artística e aprimoramento de arranjos para uma formação prática e bem-sucedida na indústria musical.
    </p>
    <p>
    ✔ Gravação e programação <br/>
    ✔ Mixagem em todos os formatos <br/>
    ✔ Masterização <br/>
    ✔ Pré e pós produção <br/>
    ✔ Microfones<br/>
    ✔ Outboards e plugins <br/>
    ✔ Sequenciadores e MÍDI <br/>
    ✔ Acústica e monitoração<br/>
    </p>`
  },
  {
    name: "THE BAND",
    describe: "",
    category: "Especial",
    image: require("../assets/images/courses/new-paste/bonham.webp"),
    information: `<p>O curso ideal para quem quer tocar em uma banda de verdade, com o acompanhamento e direcionamento de professores e diretores artísticos experientes no mercado da música. <br/>
Aulas 100% práticas nos estúdios Midas. </p>
    <p>
    ✔ Desenvolvimento de repertório <br/>
    ✔ Arranjos<br/>
    ✔ Apresentações ao vivo<br/>
    ✔ Gravações no Midas Studios<br/>
    </p>`
  },
  {
    name: "GUITAR HERO",
    describe: "Guitarra",
    category: "Musicais",
    image: require("../assets/images/courses/new-paste/guitar-hero.webp"),
    information: `<p>Foco  no desenvolvimento da sua competência musical, das técnicas especializadas, leitura de partituras e do seu desenvolvimento artístico, com base em Music Business e Produção Musical.</p>
    <p>
    ✔ Executar os principais estilos da música popular, em suas principais variações e andamentos. <br/>
    ✔ Dominar as principais escalas, arpejos e acordes. <br/>
    ✔ Conhecer os amplificadores, pedais, microfones e equipamentos de estúdio e show.<br/>
    ✔ Conhecer os diferentes tipos de setup. <br/>
    ✔ Ser capaz de se preparar para gravações e shows de forma profissional. </p>`
  },
  {
    name: "BONHAM",
    describe: "Bateria",
    category: "Musicais",
    image: require("../assets/images/courses/new-paste/bonham.webp"),
    information: `<p>Aulas práticas e teóricas  de forma expositiva e dialogada, onde serão utilizados métodos e gravações para o ensino do conteúdo planejado.</p> 
    <p>✔ Execução da condução rítmica dos principais estilos da música popular, em suas principais variações e andamentos, com foco nos gêneros mais requisitados no mercado. <br/>
    ✔ Domínio dos principais rudimentos como definidos pela PAS (Percussive Arts Society).<br/>
    ✔ Leitura de partituras para bateria<br/>
    ✔ Transcrição de repertório<br/>
    ✔ Ser capaz de se preparar para gravações e shows de forma profissional.
    </p>`
  },
  {
    name: "MAGIC KEYS",
    describe: "Teclado e Piano",
    category: "Musicais",
    image: require("../assets/images/courses/new-paste/magic-keys_50.webp"),
    information: `<p>Foco  no desenvolvimento da sua competência musical, das técnicas especializadas, leitura de partituras e do seu desenvolvimento artístico com base na realidade do mercado musical.</p> 
    <p>✔ Executar os principais estilos da música popular, em suas principais variações e andamentos, com foco nos gêneros mais requisitados do mercado. <br/>
      ✔ Dominar as principais escalas, arpejos e acordes.<br/>
    ✔ Conhecer o repertório.<br/>
    ✔ Conhecer timbres mais utilizados (tecladistas).<br/>
    ✔ Conhecer os diferentes tipos de setup. <br/>
      ✔ Ser capaz de se preparar para um show de forma profissional.
    </p>`
  },
  {
    name: "LOW",
    describe: "Contra-baixo",
    category: "Musicais",
    image: require("../assets/images/courses/new-paste/low.webp"),
    information: `<p>Teoria e técnica aplicadas na fundação da música. Desenvolver as habilidades específicas de cada aluno(a) com base na metodologia exclusiva da Midas Academy, focando na realidade do mercado musical atual.
</p> 
    <p>✔ Executar os principais estilos da música popular, em suas principais variações e andamentos<br/>
    ✔ Dominar as principais escalas e arpejos. <br/>
    ✔ Conhecer os amplificadores, pedais, microfones e equipamentos de estúdio e show.<br/>
    ✔ Conhecer os diferentes tipos de setup. <br/>
    ✔ Ser capaz de se preparar para gravações e shows de forma profissional.    
    </p>`
  },
  {
    name: "SCORE",
    describe: "Arranjos e programação",
    category: "Musicais",
    image: require("../assets/images/courses/new-paste/score.webp"),
    information: `<p>Desenvolver, intensificar e conhecer técnicas utilizadas em estúdios para programar e fazer arranjos.
    </p>
    <p>
    ✔ Aprendizagem da escrita de arranjos na partitura<br/>
    ✔ Programação de arranjos em formato midi<br/>
    ✔ Conceito artístico aplicado na pré produção e produção<br/>
    ✔ Home estúdio x estúdio: Quais as principais características e diferenças entre eles<br/>
    ✔ A&R e a importância do arranjo na indústria <br/>
    </p>`
  },
  {
    name: "BAND LEADER",
    describe: "Canto",
    category: "Musicais",
    image: require("../assets/images/courses/new-paste/score.webp"),
    information: `<p>A instrução individual ou em grupo de canto tem por objetivo a formação de cantores para atuarem no âmbito profissional. Nosso foco é o desenvolvimento da sua competência musical, das técnicas especializadas, leitura de partituras e do seu desenvolvimento artístico, com base em Music Business e Produção Musical.
    </p>
    <p>
      ✔ Interpretação<br/>
      ✔ Fisiologia da voz<br/>
      ✔ Introdução à respiração e apoio<br/>
      ✔ Desenvolvimento de Postura<br/>
      ✔ Saúde vocal<br/>
      ✔ Noções de pulso e afinação<br/>
      ✔ Alongamentos <br/>
      ✔ Notas longas<br/>
    </p>`
  },
  {
    name: "JAZZ",
    describe: "",
    category: "Dança",
    image: require("../assets/images/courses/new-paste/jazz_50.webp"),
    information: `<p>Jazz é uma forma de expressão pessoal criada e sustentada pelo improviso, combinando movimentos rítmicos com elementos do ballet, hip hop e outras danças.
    </p>
    <p>
    ✔ Trabalhar a flexibilidade e tônus<br/>
    ✔ Desenvolver e aperfeiçoar a resistência física e coordenação motora <br/>
    ✔ Trabalhar a musicalidade na dança<br/>
    ✔ Trabalhar os diferentes estilos de jazz<br/>
    </p>`
  },
  {
    name: "BALLET",
    describe: "",
    category: "Dança",
    image: require("../assets/images/courses/new-paste/balet.webp"),
    information: `<p>Turmas para adultos e crianças.<br/>
    Foco no fortalecimento e aumento da capacidade aeróbica por meio de exercícios contínuos de salto e giros.
    Encantar e motivar a prática dos movimentos usando a imaginação e criatividade sem perder a essência do ballet clássico, elegância e disciplina.</p>
    <p>
    ✔ Conhecimento e reconhecimento dos cantos da sala<br/>
    ✔ Aprender e executar as sequências dançantes<br/>
    ✔ Desenvolver atividades de equilíbrio/ força / criatividade / coordenação motora / agilidade<br/>
    ✔ Conhecer e trabalhar as nomenclaturas da dança<br/>
    ✔ Executar exercícios de alongamento e abertura<br/>
    ✔ Executar exercícios de expressão corporal e atividades lúdicas e teatrais<br/>
    </p>`
  },
  {
    name: "DANCE ACTING",
    describe: "",
    category: "Dança",
    image: require("../assets/images/courses/new-paste/performance.webp"),
    information: `<p>Foco  no aperfeiçoamento da técnica de dança, usando estilos variados como Hip Hop, Jazz e Ballet, somado à atuação e desenvoltura artística.
    Reconhecimento e entendimento dos movimentos da dança como ferramenta para contar histórias e transmitir sentimentos.
    </p>
    <p>
    ✔ Conhecimento e reconhecimento dos cantos da sala<br/>
    ✔ Trabalhar a musicalidade em sua totalidade<br/>
    ✔ Trabalhar as diferentes técnicas e expressões corporais através da dança<br/>
    ✔ Interpretação <br/>
    ✔ Desenvoltura <br/>
    ✔ Expressão de todo o corpo<br/>
    ✔ Feeling<br/>
    </p>`
  },
  {
    name: "MUSICALIZAÇÃO INFANTIL",
    describe: "",
    category: "Babies",
    image: require("../assets/images/courses/new-paste/childrens-musicalization_50.webp"),
    information: `<p>A musicalização é o processo de construção do conhecimento musical: seu principal objetivo é despertar e desenvolver o gosto pela música, estimulando e contribuindo com a formação global do ser humano.
    </p>
    <p>
    ✔ Incentivar a autodisciplina<br/>
    ✔ Estimular o raciocínio e a memória<br/>
    ✔ Desenvolver habilidades sociais<br/>
    ✔ Aumentar a autoestima<br/>
    ✔ Proporcionar divertimento<br/>
    ✔ Potencializar a criatividade <br/>
    ✔ Desenvolver a coordenação motora<br/>
    ✔ Aumentar a concentração<br/>
    </p>`
  },
  {
    name: "TEATRO",
    describe: "",
    category: "Teatro",
    image: require("../assets/images/courses/new-paste/theater_50.webp"),
    information: `<p>Forma de arte em que um ator ou conjunto de atores, interpreta uma história ou atividades para o público em um determinado lugar.<br/>
Você vai experimentar e explorar os desafios reais da sua profissão dentro e fora da sala de aula.
    </p>
    <p>
    ✔ Desenvolver movimentos de improvisação<br/>
    ✔ Desenvolver a criatividade corporal<br/>
    ✔ Exercer o poder da comunicação <br/>
    ✔ Construir e fazer caracterização de personagens<br/>
    ✔ Interpretar diferentes papeis de acordo com espaço cênico<br/>
    ✔ Utilizar corretamente a expressão,  emocional, corporal e vocal<br/>
    ✔ Criar e executar cenas e performance para montagem teatral e em outros ambientes, como shows, videoclipes, e demais apresentações.<br/>
    </p>`
  },
  {
    name: "TEATRO MUSICAL",
    describe: "",
    category: "Teatro",
    image: require("../assets/images/courses/new-paste/musical-theater_50.webp"),
    information: `<p>Teatro Musical combina música, dança, e diálogos falados. <br/>
Um processo altamente colaborativo, um espetáculo resultante da síntese de várias artes: literatura dramática, letras, músicas, parte recitativa, voz, dança, entre outras.
    </p>
    <p>
    ✔ Reconhecer o estilo predominante de dança da obra musical<br/>
    ✔ Desenvolver movimentos dentro do estilo proposto motivados por ações.<br/>
    ✔ Perceber aspectos da Concepção coreográfica do musical <br/>
    </p>`
  },
]
