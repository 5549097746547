import React from 'react';
import logo from '../../../assets/images/records/logo.png';
import Button from '../../common/Button/Button';
import './Records.scss';


const Records = () => {
  return (
    <section id='records' className='Records'>
      <div className="container-records">
        <img src={logo} alt="Logo midas" title="Logo midas" loading="lazy" />
        <div className='box-records'>
          <p className="description">
            Programa de Direção artística, produção, gravação e lançamentos direcionado para o desenvolvimento de carreiras com Rick Bonadio e sua equipe. Aqui, nossos alunos tem acesso a uma estrutura de referência na música brasileira, incluindo o complexo de estúdio Midas, com valores acessíveis para produção de singles, EP’s, álbuns e lançamentos.
          </p>
          <Button>FAÇA PARTE DO A&R CUSTOM
          </Button>
        </div>
      </div>
    </section>
  )
}

export default Records
