import React, { useEffect, useState } from 'react';
import logo from '../../../assets/images/footer/logo-teste.webp';
import { links } from "../../../constants/links";
import NavigationLink from '../../common/NavigationLink/NavigationLink';
import './Navbar.scss';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const [activeSection, setActiveSection] = useState('home');

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const sections = document.querySelectorAll('section');
    const scrollPosition = window.scrollY;

    sections.forEach((section) => {
      const sectionTop = section.offsetTop;
      const sectionHeight = section.clientHeight;
      const sectionBottom = sectionTop + sectionHeight;

      if (scrollPosition + 100 >= sectionTop && scrollPosition + 100 < sectionBottom) {
        setActiveSection(section.id);
      }
    });
  };

  useEffect(() => {
    const sections = document.querySelectorAll('section');

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    }, {
      threshold: 0.5
    });

    sections.forEach(section => observer.observe(section));

    return () => {
      sections.forEach(section => observer.unobserve(section));
    };
  }, []);

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  return (
    <div className='Navbar'>
      <button aria-label={"Abrir Menu"} className="hamburger" onClick={toggleMenu}>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </button>
      <img src={logo} alt="Logo Midas" title="Logo Midas" />
      {isOpen && (
        <div className="modal">
          <div className="modal-content">
            <NavigationLink href='/#home' active={activeSection === 'home'} onClick={handleCloseModal}>Início</NavigationLink>
            <NavigationLink href='/#cursos' active={activeSection === 'cursos'} onClick={handleCloseModal}>Cursos</NavigationLink>
            <NavigationLink href='/#diretores' active={activeSection === 'diretores'} onClick={handleCloseModal}>Diretores Artísticos</NavigationLink>
            <NavigationLink href='/#historia' active={activeSection === 'historia'} onClick={handleCloseModal}>História</NavigationLink>
            <NavigationLink href='/#records' active={activeSection === 'records'} onClick={handleCloseModal}>Midas Academy Records</NavigationLink>
            <NavigationLink href='/#store' active={activeSection === 'store'} onClick={handleCloseModal}>Midas Store</NavigationLink>
            <NavigationLink href='/#espaco' active={activeSection === 'espaco'} onClick={handleCloseModal}>Nosso espaço</NavigationLink>
            <NavigationLink href={links.contact.generic} active={activeSection === 'contato'} onClick={handleCloseModal}>Contato</NavigationLink>
          </div>
        </div>
      )}
      <div className="container-navbar">
        <NavigationLink href='/#home' active={activeSection === 'home'}>Início</NavigationLink>
        <NavigationLink href='/#cursos' active={activeSection === 'cursos'}>Cursos</NavigationLink>
        <NavigationLink href='/#diretores' active={activeSection === 'diretores'}>Diretores Artísticos</NavigationLink>
        <NavigationLink href='/#historia' active={activeSection === 'historia'}>História</NavigationLink>
        <NavigationLink href='/#records' active={activeSection === 'records'} onClick={handleCloseModal}>Midas Academy Records</NavigationLink>
        <NavigationLink href='/#store' active={activeSection === 'store'}>Midas Store</NavigationLink>
        <NavigationLink href='/#espaco' active={activeSection === 'espaco'}>Nosso espaço</NavigationLink>
        <NavigationLink href={links.contact.generic} active={activeSection === 'contato'}>Contato</NavigationLink>
      </div>
    </div>
  )
}

export default Navbar
