export const links = {
  'contact': {
    'generic': 'https://api.whatsapp.com/send?phone=5511966035555&text=Ol%C3%A1,%20Gostaria%20de%20saber%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20Midas%20Academy',
    'experimentalLesson': 'https://api.whatsapp.com/send?phone=5511966035555&text=Ol%C3%A1,%20Gostaria%20de%20agendar%20uma%20aula%20experimental',
    'becomeStudent': 'https://api.whatsapp.com/send?phone=5511966035555&text=Ol%C3%A1,%20Gostaria%20de%20ser%20aluno',
    'joinHistory': 'https://api.whatsapp.com/send?phone=5511966035555&text=Ol%C3%A1,%20Quero%20fazer%20parte%20dessa%20hist%C3%B3ria!',
    'midasStore': 'https://api.whatsapp.com/send?phone=5511966035555&text=Ol%C3%A1,%20Gostaria%20de%20ser%20avisado%20quando%20a%20MidasStore%20for%20lan%C3%A7ada!',
    'bookTour': 'https://api.whatsapp.com/send?phone=5511966035555&text=Ol%C3%A1,%20Gostaria%20de%20agendar%20uma%20visita',
    'facebook': 'https://www.facebook.com/profile.php?id=61559137654285',
    'tiktok': 'https://www.tiktok.com/@midasacademyofc?_t=8m3EoLN7zGP&_r=1',
    'instagram': 'https://instagram.com/midasacademyofc'
  },
  'map': {
    'google': 'https://maps.app.goo.gl/dgC7wPnHBoaZ5uLu5',
    'address': 'R. Leão XIII, 166 - Jd. São Bento  São Paulo, SP - 02526-000',
    'hours': 'Das 09:00 às 18:00',
  },
  'phone': {
    'verbose': '(11) 96603-5555',
    'link': 'tel:11966035555',
  },
  'mail': {
    'verbose': 'contato@midasacademy.com.br',
    'link': 'mailto:contato@midasacademy.com.br',
  }
}