import React from 'react';
import './Teacher.scss';

function Teacher({isOpen, onClose, course }) {
  return (
    <>
      {isOpen && (
        <div className="popup-background" onClick={onClose}>
          <div className="popup-content-teacher" onClick={onClose}>
            <span onClick={onClose}>&times;</span>
            {course && (
              <div className='teacher'>
                <h2>{course.name + " " + course.twoName}</h2>
                <img className={course.name} src={course.image} alt={course.name} title={course.name} loading="lazy"/>
                <p dangerouslySetInnerHTML={{ __html: course.information}}></p>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default Teacher;
