import React, { useState } from 'react';
import './CoursePopUp.scss';

function CoursePopUp({isOpen, onClose, course }) {

  return (
    <>
      {isOpen && (
        <div className="popup-background" onClick={onClose}>
          <div className="popup-content" onClick={onClose}>
            <span onClick={onClose}>&times;</span>
            {/* Renderize os dados do curso no popup */}
            {course && (
              <>
                <h2>{course.name}</h2>
                <p dangerouslySetInnerHTML={{ __html: course.information}}></p>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default CoursePopUp;
