import React from 'react'
import './Biography.scss'
import th from '../../../assets/images/biography/th-fiuk_50.webp'
import luiza from '../../../assets/images/biography/luiza_50.webp'
import daniel from '../../../assets/images/biography/daniel_1_50.webp'
import jao from '../../../assets/images/biography/jao-vitor_1_50.webp'
import five from '../../../assets/images/biography/five_1_50.webp'
import six from '../../../assets/images/biography/six_50.webp'
import seven from '../../../assets/images/biography/seven_50.webp'
import eight from '../../../assets/images/biography/eight_1_50.webp'
import nine from '../../../assets/images/biography/nine_50.webp'
import ten from '../../../assets/images/biography/ten.webp'
import eleven from '../../../assets/images/biography/eleven_1_50.webp'
import twelve from '../../../assets/images/biography/twelve_50.webp'
import thirteen from '../../../assets/images/biography/thirteen_50.webp'

const Biography = () => {

  return (
    <section id='historia' className='Biography'>
      <div className="container-photos">
        <div className='photo one'>
          <div style={{ backgroundImage: `url(${th})` }} className="photo-url" />
          <div className='name'>
            <p>Eu, Thiaguinho e Fiuk.</p>
          </div>
        </div>
        <div className='photo two'>
          <div style={{ backgroundImage: `url(${luiza})` }} className="photo-url" />
          <div className='name'>
            <p>Eu e Luiza Possi.</p>
          </div>
        </div>
        <div className='photo three'>
          <div style={{ backgroundImage: `url(${daniel})` }} className="photo-url" />
          <div className='name'>
            <p>Eu e Daniel.</p>
          </div>
        </div>
        <div className='photo four'>
          <div style={{ backgroundImage: `url(${jao})` }} className="photo-url" />
          <div className='name'>
            <p>Eu, Jão e Vitor Kley.</p>
          </div>
        </div>

        <div className='photo five'>
          <div style={{ backgroundImage: `url(${five})` }} className="photo-url" />
          <div className='name'>
            <p>Eu, Jão e Vitor Kley.</p>
          </div>
        </div>

        <div className='photo six'>
          <div style={{ backgroundImage: `url(${six})` }} className="photo-url" />
          <div className='name'>
            <p>Eu, Jão e Vitor Kley.</p>
          </div>
        </div>

        <div className='photo seven'>
          <div style={{ backgroundImage: `url(${seven})` }} className="photo-url" />
          <div className='name'>
            <p>Eu, Jão e Vitor Kley.</p>
          </div>
        </div>

        <div className='photo eight'>
          <div style={{ backgroundImage: `url(${eight})` }} className="photo-url" />
          <div className='name'>
            <p>Eu, Jão e Vitor Kley.</p>
          </div>
        </div>

        <div className='photo nine'>
          <div style={{ backgroundImage: `url(${nine})` }} className="photo-url" />
          <div className='name'>
            <p>Eu, Jão e Vitor Kley.</p>
          </div>
        </div>

        <div className='photo ten'>
          <div style={{ backgroundImage: `url(${ten})` }} className="photo-url" />
          <div className='name'>
            <p>Eu, Jão e Vitor Kley.</p>
          </div>
        </div>

        <div className='photo eleven'>
          <div style={{ backgroundImage: `url(${eleven})` }} className="photo-url" />
          <div className='name'>
            <p>Eu, Jão e Vitor Kley.</p>
          </div>
        </div>
        <div className='photo twelve'>
          <div style={{ backgroundImage: `url(${twelve})` }} className="photo-url" />
          <div className='name'>
            <p>Eu, Jão e Vitor Kley.</p>
          </div>
        </div>
        <div className='photo thirteen'>
          <div style={{ backgroundImage: `url(${thirteen})` }} className="photo-url" />
          <div className='name'>
            <p>Eu, Jão e Vitor Kley.</p>
          </div>
        </div>
      </div>
      <div className="container-biography">
        <p>Caro aluno-artista, <br /><br />
          Estou muito feliz em recebê- lo aqui e iniciar a jornada de desenvolvimento da sua carreira!
          <br /><br />
          Estamos vivendo um momento em que a música está precisando de talentos verdadeiros e que os novos artistas como você, que querem revolucionar a música com qualidade, precisam de apoio e direcionamento de pessoas acostumadas com o mercado.
          <br /><br />
          Tudo começa com um sonho!<br />
          Sonhamos em tocar em uma banda…<br />
          Sonhamos em dançar e atuar em um grande musical…<br />
          Sonhamos em fazer sucesso e viver da nossa arte…
          <br /><br />
          Com a minha experiência dirigindo e revelando grandes artistas  juntamente com os melhores professores-artistas do mercado, vamos juntos realizar o seu sonho de se tornar um grande artista.
        </p>
        <p className='assinature'>Com carinho, <br />
          Rick Bonadio</p>
      </div>
    </section>
  )
}

export default Biography
